<template>
  <el-card>
    <div slot="header">
      <h3>我的文章及经验观点</h3>
    </div>

    <el-image :src="url" fit="fill"></el-image>
  </el-card>
</template>

<script>
  export default {
    name: 'ArticleIndex',
    data(){
      return {
        url : require('../../../assets/images/undev.png')
      }
    }
  }
</script>

<style scoped lang="scss">
  .el-card{
    min-height: 1050px;

    .el-card__header {
      h3 {
        margin: 0;
        padding: 0;
      }
    }
  }

</style>
